<template>
    <specialty-profile-component/>
</template>

<script>
    import SpecialtyProfileComponent from "@/components/medical-services/SpecialtyProfileComponent";
    
    export default {
        name: "SpecialtyProfile",
        title: "Perfil de la Especialidad | Turismo BC",
        components: { SpecialtyProfileComponent }
    }
</script>

<style scoped>

</style>