<template>
    <div class="fill-height container--fluid" style="width: 100%">
        <div class="fill-height container--fluid">
            <v-toolbar tile flat>
                <v-btn icon v-on:click="goBack">
                    <v-icon>mdi-arrow-left</v-icon>
                </v-btn>
                <v-toolbar-title>Ir atrás</v-toolbar-title>
            </v-toolbar>
        </div>
        <v-container fluid class="mx-0 justify-start">
            <v-row align="start" justify="start">
                <v-col cols="12" md="3">
                    <v-sheet class="mt-5">
                        <v-card
                                flat
                                hover
                                elevation="0"
                                class="rounded-lg">
                            <v-img  v-if="profile.pictures[0] != null"
                                    :src="profile.pictures != null ? profile.pictures[0].url : require('@/assets/images/no-image.jpg')"
                                    :lazy-src="require('@/assets/images/no-image.jpg')"
                                    position="top"
                                    contain
                                    height="200">
                                <template v-slot:placeholder>
                                    <v-row  class="fill-height ma-0"
                                            align="center"
                                            justify="center">
                                        <v-progress-circular
                                                indeterminate
                                                color="grey lighten-5"
                                        ></v-progress-circular>
                                    </v-row>
                                </template>
                            </v-img>
                            <v-card-title>
                                <span class="indigo--text text-truncate">
                                    {{ profile.name }}
                                </span>
                            </v-card-title>
                            <v-card-text class="indigo--text">
                                {{ profile.description }}
                            </v-card-text>
                        </v-card>
                    </v-sheet>
                </v-col>
                <v-col cols="12" md="9">
                    <v-container fluid class="mx-0 justify-start">
                        <v-row justify="start" align="start" dense>
                            <v-col cols="12">
                                <h1 class="text-h5 font-weight-regular headline text-uppercase">
                                    Especialistas
                                </h1>
                            </v-col>
                            <v-col cols="12">
                                <div class="boxdivider"></div>
                            </v-col>
                            <v-col cols="12">
                                <v-container fluid class="fill-height justify-start px-0">
                                    <v-row justify="start" align="start">
                                        <v-col  v-for="(item, index) in profile.doctors"
                                                :key="index"
                                                class="d-flex child-flex"
                                                col="12" md="4">
                                            <v-card v-if="item.pictures != undefined"
                                                    outlined
                                                    elevation="10"
                                                    class="rounded-lg">
                                                <v-img  :src="item.pictures != undefined ? item.pictures[0].url : require('@/assets/images/no-image.jpg')"
                                                        :lazy-src="require('@/assets/images/no-image.jpg')"
                                                        position="top"
                                                        aspect-ratio="1"
                                                        height="250">
                                                    <template v-slot:placeholder>
                                                        <v-row
                                                            class="fill-height ma-0"
                                                            align="center"
                                                            justify="center">
                                                            <v-progress-circular
                                                                indeterminate
                                                                color="grey lighten-5">
                                                            </v-progress-circular>
                                                        </v-row>
                                                    </template>
                                                </v-img>
                                                <v-btn v-model="selectedId"
                                                       plain
                                                       text
                                                       elevation="0"
                                                       class="mx-0 mt-1"
                                                       @click="showProfile(item.id)">
                                                    <span class="pb-0 indigo--text text-subtitle-2 text-truncate" v-text="item.name"></span>
                                                </v-btn>

                                                <v-card-actions class="mt-1 mb-1 justify-center">
                                                    <v-btn  color="blue"
                                                            icon
                                                            :href="item.facebook_profile"
                                                            target="_blank">
                                                        <v-icon size="24px" color="primary">mdi-facebook</v-icon>
                                                    </v-btn>
                                                    <v-btn  color="blue"
                                                            icon
                                                            :href="item.instagram_profile"
                                                            target="_blank">
                                                        <v-icon size="24px" color="primary">mdi-instagram</v-icon>
                                                    </v-btn>
                                                    <v-btn color="blue"
                                                           icon
                                                           :href="item.twitter_profile"
                                                           target="_blank">
                                                        <v-icon size="24px" color="primary">mdi-twitter</v-icon>
                                                    </v-btn>
                                                    <v-btn  color="blue"
                                                            icon
                                                            :href="item.youtube_video"
                                                            target="_blank">
                                                        <v-icon size="24px" color="primary">mdi-youtube</v-icon>
                                                    </v-btn>
                                                </v-card-actions>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-col>
                            <v-col cols="12">
                                <v-row justify="center" class="ma-3" v-if="profile.doctors.length === 0">
                                    <div>
                                        No hay Especialistas en el catálogos...
                                    </div>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-col>
            </v-row>
        </v-container>
        <footer-profile-component/>
    </div>
</template>

<script>
    import specialtiesService from '@/providers/SpecialtiesService';
    import FooterProfileComponent from "@/layouts/app-layout/footer/FooterProfileComponent";
    
    export default {
        name: "SpecialtyProfileComponent",
        components: { FooterProfileComponent },
        data: () => ({
            selection: -1,
            selectedId: 0,
            model: null,
            profile: {
                id: 0,
                name: null,
                description: null,
                pictures: [],
                doctors: []
            },
        }),
        methods: {
            goBack() {
                this.$router.push('/specialties-services');
            },
            toTop() {
                this.$vuetify.goTo(0);
            },
            getProfile(idSpecialty) {
                let record = null;
                specialtiesService.getRecord(idSpecialty).then( fetch_data => {
                    record = fetch_data.value;
                    // console.log('record:', record);
                    this.profile.id = record.id;
                    this.profile.name = record.name;
                    this.profile.description = record.description;
                    this.profile.pictures = record.pictures;
                    this.profile.doctors = record.doctors;
                    // console.log('profile:', this.profile);
                    this.toTop();
                });
            },
            showProfile(idDoctor) {
                this.selectedId = idDoctor;
                this.$router.push({
                    name: "DoctorProfile",
                    params: {
                        id: idDoctor,
                    }
                });
            }
        },
        mounted() {
            // console.log('param:', this.$route.params.id);
            this.getProfile(this.$route.params.id);
        },
    }
</script>

<style scoped>
    .v-btn {
        text-transform: none !important;
    }
    .boxdivider {
        background-color: transparent;
        width: 100%;
        height: 0px;
        color: #ffffff;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        border-bottom-width: 3px;
        border-bottom-color: blueviolet;
        border-bottom-style: solid;
        display: block;
    }
</style>